import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import useUntrustedDeviceRecoverDeclined from 'web/src/modules/restore-password/submodules/UntrustedDeviceRecoverDeclined/composables/useUntrustedDeviceRecoverDeclined';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UntrustedDeviceRecoverDeclined',
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { jumbotronSuccessProperties, buttonLinkProperties, emitClose, openHelpModal } = useUntrustedDeviceRecoverDeclined(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                class: _ctx.$style['udrd']
            }, _unref(jumbotronSuccessProperties)), {
                footer: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['udrd--buttons'])
                        }, [
                            _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(buttonLinkProperties), {
                                onClick: _unref(emitClose)
                            }), null, 16, [
                                "onClick"
                            ]),
                            _renderSlot(_ctx.$slots, "contacts-button", {}, ()=>[
                                    _createElementVNode("a", {
                                        class: _normalizeClass(_ctx.$style['udrd--support']),
                                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(openHelpModal) && _unref(openHelpModal)(...args);
                                        })
                                    }, _toDisplayString(_ctx.$t('WEB2_ERROR_BTN_SUPPORT')), 3)
                                ])
                        ], 2)
                    ]),
                _: 3
            }, 16, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'UntrustedDeviceRecoverDeclined'
                ]
            ]);
        };
    }
});
