import { computed, toRef } from 'vue';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import { useFormsStore } from 'web/src/modules/forms/store';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { FormControlType, FormFocusType } from 'web/src/components/Form/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestorePasswordByPhoneForm(emits) {
    const { $translate } = useI18n();
    const { getDefaultPhoneValueForForm } = useFormDefaultFormPhoneValue();
    const restoreBySendSms = toRef(useFormsStore(), 'restoreBySendSms');
    const captchaSettings = toRef(useRestorePasswordStore(), 'captchaSettings');
    const formSchema = computed(()=>restoreBySendSms.value ?? {
            properties: {}
        });
    // eslint-disable-next-line rulesdir/class-method-use-this-regex
    const uiSchema = computed(()=>({
            order: [
                RestorePasswordFieldName.PHONE,
                RestorePasswordFieldName.CAPTCHA_TOKEN
            ],
            submitButton: {
                id: 'restorePasswordByPhoneButton',
                label: $translate('WEB2_RESTORE_PASSWORD_NEXT').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: 'phone'
            },
            fields: {
                [RestorePasswordFieldName.PHONE]: {
                    title: $translate('RESTORE_PASSWORD_INPUT_PHONE_NUMBER').value,
                    widget: FormControlType.Phone,
                    default: getDefaultPhoneValueForForm(),
                    options: {
                        placeholder: $translate('WEB2_PHONE_INPUT_PLACEHOLDER').value
                    }
                },
                [RestorePasswordFieldName.CAPTCHA_TOKEN]: {
                    hidden: true,
                    widget: FormControlType.Captcha,
                    options: {
                        captchaRequesterStrategy: CaptchaRequesterStrategy.RESTORE_PASSWORD,
                        captchaEnabled: captchaSettings.value.isEnabledToShow,
                        reCaptchaTheme: captchaSettings.value.theme,
                        isEnforced: captchaSettings.value.isEnforced
                    }
                }
            }
        }));
    function emitSubmit(formData) {
        emits('form-submit', formData);
    }
    function emitInput(payload) {
        emits('form-input', payload);
    }
    return {
        formSchema,
        uiSchema,
        emitSubmit,
        emitInput
    };
}
