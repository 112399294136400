import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { isString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config-names';
import { VSpinnerSize, VSpinnerColor } from '@components/spinner';
import { UntrustedSteps } from 'web/src/modules/restore-password/submodules/enums';
import useCasAuthStore from 'web/src/modules/cas/store/useCasAuthStore';
import { useUserStore } from 'web/src/modules/user/store';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useUntrustedDeviceRouteComponent() {
    const step = ref(UntrustedSteps.INITIALIZATION);
    const hashId = ref('');
    const { $translate } = useI18n();
    const router = useRouter();
    const casAuthStore = useCasAuthStore();
    const userStore = useUserStore();
    const loaderProperties = computed(()=>({
            size: VSpinnerSize.LARGE,
            color: VSpinnerColor.BRANDED,
            title: $translate('WEB2_DEVICE_RECOVER_CHECK').value,
            hint: $translate('WEB2_DEVICE_RECOVER_WAIT').value
        }));
    async function beforeMounted() {
        const hashIdTemp = router.getParam('hashId') || '';
        isString(hashIdTemp);
        hashId.value = hashIdTemp;
        step.value = UntrustedSteps.RECOVER;
        try {
            await userStore.markDeviceUntrusted({
                hash: hashId.value,
                password: ''
            });
            await casAuthStore.getSavedLogins();
            await casAuthStore.deleteSavedLogins({
                username: casAuthStore.usernames[0]
            });
            step.value = UntrustedSteps.RECOVER;
        } catch  {
            step.value = UntrustedSteps.DECLINED;
        }
    }
    function handleClose() {
        "1";
        router.closeModal();
    }
    function handleLogin() {
        router.replace({
            name: RouteName.LOGIN
        });
    }
    return {
        step,
        loaderProperties,
        hashId,
        handleLogin,
        handleClose,
        beforeMounted
    };
}
