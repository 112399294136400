import RouteName from '@leon-hub/routing-config';
import { getPinCodeStatus, PinCodeState as PinCodeStateEnum } from '@leon-hub/api-sdk';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useI18n } from 'web/src/modules/i18n/composables';
import usePinCodeStore from '../../pin-code/store/usePinCodeStore';
let ResetPinRoutePagePrefetch = class ResetPinRoutePagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        const pinCodeStore = usePinCodeStore();
        const apiClient = useGraphqlClient();
        const { $translate } = useI18n();
        if (to.params.token) pinCodeStore.resetPinCodeByEmail(String(to.params.token)).then(()=>getPinCodeStatus(apiClient, (node)=>node.queries.pinCode.getPinCodeStatus)).then((result)=>{
            if (result.status !== PinCodeStateEnum.RESET) throw new Error($translate('WEB2_CONFIRM_EMAIL_CAPTION_WRONG_TOKEN').value);
            pinCodeStore.resetPinCodeToken({
                value: String(to.params.token),
                phoneReset: false
            });
            pinCodeStore.setStepWithLS('CREATE');
            pinCodeStore.setStatusIgnored(true);
            next(RouteName.PIN_CODE);
        }).catch((error)=>{
            pinCodeStore.setError(error.message);
            next();
        });
        else next();
    }
};
export { ResetPinRoutePagePrefetch as default };
