import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
import { LoginType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { VLoader } from '@components/loader';
import RestoreContainer from 'web/src/modules/restore-password/components/RestoreContainer/RestoreContainer.vue';
import RestorePasswordByEmailForm from 'web/src/modules/restore-password/components/RestorePasswordByEmailForm/RestorePasswordByEmailForm.vue';
import RestorePasswordByPhoneForm from 'web/src/modules/restore-password/components/RestorePasswordByPhoneForm/RestorePasswordByPhoneForm.vue';
import { useRestorePasswordMainRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordMainRoutePage',
    setup (__props) {
        const { isRestoreByPhoneSent, isRestoreByEmailSent, isMainFormReady, activeTab, isSubmitPending, resendRestorePasswordLinkToEmail, onInput, sendSmsWrapped, sendUidWrapped } = useRestorePasswordMainRoutePage();
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(RestoreContainer, {
                "is-restore-by-phone-sent": _unref(isRestoreByPhoneSent),
                "is-restore-by-email-sent": _unref(isRestoreByEmailSent),
                onResendRestorePasswordByEmail: _unref(resendRestorePasswordLinkToEmail),
                onResendEmailInput: _unref(onInput)
            }, {
                default: _withCtx(()=>[
                        _unref(isMainFormReady) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _unref(activeTab) === _unref(LoginType).PHONE ? _withDirectives((_openBlock(), _createBlock(RestorePasswordByPhoneForm, {
                                key: 0,
                                "is-pending": _unref(isSubmitPending),
                                onFormSubmit: _unref(sendSmsWrapped),
                                onFormInput: _unref(onInput)
                            }, null, 8, [
                                "is-pending",
                                "onFormSubmit",
                                "onFormInput"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'restore-password-by-phone'
                                    }
                                ]
                            ]) : _unref(activeTab) === _unref(LoginType).EMAIL ? _withDirectives((_openBlock(), _createBlock(RestorePasswordByEmailForm, {
                                key: 1,
                                "is-pending": _unref(isSubmitPending),
                                onFormSubmit: _unref(sendUidWrapped),
                                onFormInput: _unref(onInput)
                            }, null, 8, [
                                "is-pending",
                                "onFormSubmit",
                                "onFormInput"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'restore-password-by-email'
                                    }
                                ]
                            ]) : _createCommentVNode("", true)
                        ], 64)) : (_openBlock(), _createBlock(_unref(VLoader), {
                            key: 0
                        }))
                    ]),
                _: 1
            }, 8, [
                "is-restore-by-phone-sent",
                "is-restore-by-email-sent",
                "onResendRestorePasswordByEmail",
                "onResendEmailInput"
            ])), [
                [
                    _directive_auto_id,
                    'RestorePasswordMainRoutePage'
                ]
            ]);
        };
    }
});
