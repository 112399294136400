import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
let RestorePasswordAppPrefetch = class RestorePasswordAppPrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    prefetch(router, to, from, next) {
        try {
            const applink = atob(String(to.params.applink));
            if (!process.env.VUE_APP_OS_ANDROID && applink.includes('/pwdreset')) {
                window.location.href = applink.slice(applink.indexOf('/pwdreset'));
                return Promise.resolve();
            }
            window.location.href = applink;
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(error);
        }
        return Promise.resolve();
    }
};
export { RestorePasswordAppPrefetch as default };
