import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { JumbotronFooterButton, JumbotronIconKind, VJumbotron } from 'web/src/components/Jumbotron';
import usePinCodeStore from '../../../pin-code/store/usePinCodeStore';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ResetPinRoutePage',
    props: {
        token: {}
    },
    setup (__props) {
        const router = useRouter();
        const pincodeStore = usePinCodeStore();
        const message = toRef(pincodeStore, 'error');
        function onRetry() {
            "1";
            router.push({
                name: RouteName.RESTORE_PIN
            });
        }
        onBeforeUnmount(()=>{
            pincodeStore.setError('');
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), {
                class: _normalizeClass(_ctx.$style['reset-pin']),
                icon: _unref(IconName).ATTENTION_OUTLINE,
                "icon-kind": _unref(JumbotronIconKind).DEFAULT,
                heading: message.value
            }, {
                footer: _withCtx(()=>[
                        _createVNode(_unref(JumbotronFooterButton), {
                            label: _ctx.$t('WEB2_RETRY'),
                            "full-width": "",
                            onClick: onRetry
                        }, null, 8, [
                            "label"
                        ])
                    ]),
                _: 1
            }, 8, [
                "class",
                "icon",
                "icon-kind",
                "heading"
            ])), [
                [
                    _directive_auto_id,
                    'ResetPinRoutePage'
                ]
            ]);
        };
    }
});
