import { computed, toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { RestorePasswordStep } from 'web/src/modules/restore-password/enums';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestorePasswordTopBarRoute(props) {
    const { $translate } = useI18n();
    const restorePasswordStore = useRestorePasswordStore();
    const pinCodeStore = usePinCodeStore();
    const router = useRouter();
    const route = useRoute();
    const { closeAllDialogs } = useDialogs();
    const isRestoreByEmailSent = toRef(pinCodeStore, 'isRestoreByEmailSent');
    const isRestoreByPhoneSent = toRef(pinCodeStore, 'isRestoreByPhoneSent');
    const prevStep = toRef(pinCodeStore, 'prevStep');
    const currentEmail = toRef(restorePasswordStore, 'currentEmail');
    const currentPhone = toRef(restorePasswordStore, 'currentPhone');
    const verifiedEmail = toRef(restorePasswordStore, 'verifiedEmail');
    const verifiedPhone = toRef(restorePasswordStore, 'verifiedPhone');
    const willVerifiedEmail = toRef(restorePasswordStore, 'willVerifiedEmail');
    const stepName = computed(()=>{
        if (!currentEmail.value && !willVerifiedEmail.value && !verifiedEmail.value && !currentPhone.value && !verifiedPhone.value) return RestorePasswordStep.SELECT_TYPE;
        if (null !== currentPhone.value && null === verifiedPhone.value) return RestorePasswordStep.CONFIRM_PHONE;
        if (null === currentPhone.value && null !== verifiedPhone.value) return RestorePasswordStep.ENTER_NEW_PASSWORD;
        if (willVerifiedEmail.value && !verifiedEmail.value) // LEONWEB-7116
        return false === verifiedEmail.value ? RestorePasswordStep.INVALID_LINK : RestorePasswordStep.EMAIL_INFO;
        if (willVerifiedEmail.value && verifiedEmail.value) return RestorePasswordStep.ENTER_NEW_PASSWORD;
        if (null !== currentEmail.value) return RestorePasswordStep.EMAIL_INFO;
        return RestorePasswordStep.UNKNOWN;
    });
    const isBackArrowActive = computed(()=>{
        const step = stepName.value;
        return step === RestorePasswordStep.SELECT_TYPE || step === RestorePasswordStep.CONFIRM_PHONE;
    });
    const backIconName = computed(()=>isBackArrowActive.value ? IconName.ARROW_LEFT : IconName.CROSS);
    const title = computed(()=>{
        switch(stepName.value){
            case RestorePasswordStep.ENTER_NEW_PASSWORD:
                return $translate('WEB2_RESTORE_PASSWORD_ENTER_NEW_PASSWORD').value;
            case RestorePasswordStep.CONFIRM_PHONE:
                return $translate('RESTORE_PASSWORD_NUMBER_CHECK').value;
            default:
                if (props.caption) return props.caption;
                return $translate('RESTORE_PASSWORD_TITLE').value;
        }
    });
    const showPrefix = computed(()=>{
        if (props.isPinCode) return !!prevStep.value && !isRestoreByEmailSent.value || isRestoreByPhoneSent.value;
        if (route.redirectedFrom?.name === RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE || backIconName.value === IconName.CROSS) return false;
        return !currentEmail.value || false;
    });
    function handlePrefixPasswordClick() {
        if (isBackArrowActive.value && stepName.value !== RestorePasswordStep.SELECT_TYPE) {
            restorePasswordStore.clearCurrentRestoreData();
            return;
        }
        "1";
        router.back();
    }
    function handlePrefixPinCodeClick() {
        const isCountdownOnLogin = 'COUNTDOWN' === prevStep.value && !props.isInProfile;
        let routeTo = RouteName.PIN_CODE_PROFILE;
        if (isRestoreByPhoneSent.value) {
            pinCodeStore.setIsRestoreByPhoneSent(false);
            return;
        }
        if (isRestoreByEmailSent.value) {
            pinCodeStore.setIsRestoreByEmailSent(false);
            return;
        }
        if ('LOGIN' === prevStep.value || isCountdownOnLogin) routeTo = RouteName.PIN_CODE;
        pinCodeStore.setStepWithLS(prevStep.value);
        router.replace({
            name: routeTo
        });
    }
    function onPrefixClick() {
        if (props.isPinCode) return handlePrefixPinCodeClick();
        return handlePrefixPasswordClick();
    }
    function onSuffixClick() {
        "1";
        // phone layout has no suffix
        router.closeModal();
    }
    return {
        title,
        showPrefix,
        backIconName,
        onPrefixClick,
        onSuffixClick
    };
}
