import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import useRestorePasswordTopBarRouteComposable from 'web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/composables/useRestorePasswordTopBarRouteComponent';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordTopBarRouteComponent',
    props: {
        caption: {},
        isPinCode: {
            type: Boolean
        },
        isInProfile: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { title, showPrefix, backIconName, onPrefixClick, onSuffixClick } = useRestorePasswordTopBarRouteComposable(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: _unref(title),
                "hide-prefix": !_unref(showPrefix),
                "custom-prefix-icon-name": _unref(backIconName),
                "only-emit-events": "",
                onBack: _unref(onPrefixClick),
                onClose: _unref(onSuffixClick)
            }, null, 8, [
                "title",
                "hide-prefix",
                "custom-prefix-icon-name",
                "onBack",
                "onClose"
            ])), [
                [
                    _directive_auto_id,
                    'RestorePasswordTopBarRouteComponent'
                ]
            ]);
        };
    }
});
