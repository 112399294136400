import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import useUntrustedDeviceRecoverForm from 'web/src/modules/restore-password/submodules/UntrustedDeviceRecoverForm/composables/useUntrustedDeviceRecoverForm';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { VForm } from 'web/src/components/Form';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UntrustedDeviceRecoverForm',
    props: {
        hashId: {}
    },
    emits: [
        "success"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { customFormProperties, onSubmit } = useUntrustedDeviceRecoverForm(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['udr'])
            }, [
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['udr--description'])
                }, _toDisplayString(_ctx.$t('WEB2_DEVICE_RECOVER_FORM_DESCRIPTION_1')), 3),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['udr--description'])
                }, _toDisplayString(_ctx.$t('WEB2_DEVICE_RECOVER_FORM_DESCRIPTION_2')), 3),
                _createVNode(_unref(VForm), _mergeProps(_unref(customFormProperties), {
                    onSubmit: _unref(onSubmit)
                }), null, 16, [
                    "onSubmit"
                ]),
                _createVNode(VReCaptchaBadge, {
                    lang: _ctx.$lang
                }, null, 8, [
                    "lang"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'UntrustedDeviceRecoverForm'
                ]
            ]);
        };
    }
});
