const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    title: 'Untrusted',
    required: [
        'password'
    ],
    properties: {
        password: {
            type: 'string',
            minLength: 8,
            pattern: '^(?=.*[a-zA-Z])(?=.*[0-9]).*$'
        }
    }
};
export default schema;
