import { computed, ref, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import untrustedSchema from 'web/src/modules/restore-password/submodules/schemas/untrustedSchema';
import { FormControlType } from 'web/src/components/Form/enums';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getPasswordErrorTitle } from 'web/src/modules/profile/pages/PasswordChangeRoutePage/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
export default function useUntrustedDeviceRecoverForm(props, emit) {
    const DEFAULT_RESTART_TIME = 5;
    const loading = false;
    const customErrors = ref({});
    let timer = 0;
    const siteConfigStore = useSiteConfigStore();
    const { $translate } = useI18n();
    const { showDialog, closeDialog } = useDialogs();
    const userStore = useUserStore();
    const errorsConverter = useErrorsConverter();
    const untrustedDevicePasswordResetRedirectTimer = toRef(siteConfigStore, 'untrustedDevicePasswordResetRedirectTimer');
    const countdown = computed(()=>untrustedDevicePasswordResetRedirectTimer.value || DEFAULT_RESTART_TIME);
    const uiSchema = computed(()=>({
            order: [
                'password'
            ],
            submitButton: {
                label: $translate('WEB2_REGISTRATION_CHANGE_PASSWORD').value
            },
            fields: {
                password: {
                    options: {
                        autocomplete: 'new-password',
                        placeholder: $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value
                    },
                    default: '',
                    disabled: false,
                    title: $translate('RESTORE_PASSWORD_INPUT_NEW_PASSWORD').value,
                    hidden: false,
                    widget: FormControlType.PasswordValidator
                }
            }
        }));
    const customFormProperties = computed(()=>({
            schema: untrustedSchema,
            uiSchema: uiSchema.value,
            isPending: loading,
            customErrors: customErrors.value
        }));
    const params = computed(()=>({
            time: countdown.value.toString()
        }));
    async function onSubmit(data) {
        try {
            await userStore.markDeviceUntrusted({
                hash: props.hashId,
                // eslint-disable-next-line @typescript-eslint/no-base-to-string
                password: data.formData.password.toString()
            });
            handleSuccess();
        } catch (rawError) {
            const error = errorsConverter.convertToBaseError(rawError);
            const title = getPasswordErrorTitle(rawError);
            if (error.code.equals('PASSWORDS_SAME')) customErrors.value = {
                password: [
                    {
                        message: $translate('WEB2_UNTRUSTED_DEVICE_SAME_PASSWORDS').value,
                        // eslint-disable-next-line @typescript-eslint/no-base-to-string
                        value: data.formData.password.toString()
                    }
                ]
            };
            else handleError(title, error.message);
        }
    }
    function handleSuccess() {
        const { subscribe, id } = showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                title: $translate('WEB2_PROFILE_UPDATE_PASSWORD').value,
                confirmMessage: $translate('WEB2_DEVICE_RECOVER_SUCCESS', params).value,
                buttons: [
                    {
                        label: $translate('WEB2_ACCESS_DENIED_ERROR_BUTTON').value
                    }
                ]
            }
        });
        subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                emitSuccess(id);
            }
        });
        timer = Timer.setTimeout(()=>{
            emitSuccess(id);
        }, 1000 * countdown.value);
    }
    function handleError(title, error) {
        if (!error?.length) return;
        showDialog({
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate(title).value,
                confirmMessage: error
            }
        });
    }
    function emitSuccess(id) {
        emit('success', id);
        Timer.clearTimeout(timer);
        timer = 0;
        closeDialog(id);
        return true;
    }
    return {
        customFormProperties,
        onSubmit
    };
}
