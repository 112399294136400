import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { LoginType } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { ApiError } from '@leon-hub/api';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { getImageOrIcon } from 'web/src/modules/icons';
import { FormControlType, FormFocusType } from 'web/src/components/Form/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestorePasswordLastRoutePage() {
    const { $translate } = useI18n();
    const router = useRouter();
    const formsStore = useFormsStore();
    const { showDialog, closeAllDialogs } = useDialogs();
    const restorePasswordStore = useRestorePasswordStore();
    const restoreByEmail = toRef(formsStore, 'restoreByEmail');
    const restoreByPhone = toRef(formsStore, 'restoreByPhone');
    const restorePasswordLastStepType = toRef(restorePasswordStore, 'restorePasswordLastStepType');
    const fetchInitErrorMessage = toRef(restorePasswordStore, 'fetchInitErrorMessage');
    const isPending = ref(false);
    const iconProperties = computed(()=>({
            icon: IconName.INFO_OUTLINE,
            iconSize: IconSize.SIZE_60
        }));
    const formSchema = computed(()=>{
        if (restorePasswordLastStepType.value === LoginType.EMAIL) return restoreByEmail.value;
        if (restorePasswordLastStepType.value === LoginType.PHONE) return restoreByPhone.value;
    });
    const uiSchema = computed(()=>({
            submitButton: {
                label: $translate('JS_REG_NEEDED_FIELDS_SAVE').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: 'pwd'
            },
            fields: {
                pwd: {
                    widget: FormControlType.PasswordValidator,
                    title: $translate('RESTORE_PASSWORD_INPUT_NEW_PASSWORD').value,
                    options: {
                        placeholder: $translate('WEB2_NEW_PASSWORD_INPUT_PLACEHOLDER').value
                    }
                }
            }
        }));
    function runBeforeUnmount() {
        restorePasswordStore.clearVerifiedData();
        restorePasswordStore.clearCurrentRestoreData();
        restorePasswordStore.setFetchInitErrorMessage(null);
    }
    async function restorePassword(formOutput) {
        const { errors } = formOutput;
        const restoreType = restorePasswordLastStepType.value;
        if (errors || !restoreType) return;
        try {
            isPending.value = true;
            if (restoreType === LoginType.PHONE) await restorePasswordStore.doRestorePasswordByPhoneStore(formOutput);
            else await restorePasswordStore.doRestorePasswordByEmailStore(formOutput);
            openSuccessModal();
        } catch (rawError) {
            const error = normalizeError(rawError);
            if (rawError instanceof ApiError && 'COMMON_PASSWORD' === String(rawError.code)) showDialog({
                presetName: PresetName.ALERT_ERROR,
                options: {
                    title: $translate('JS_CAPTION_ATTENTION').value,
                    analyticsModalType: void 0,
                    confirmMessage: error.message,
                    width: ModalWidth.SMALL,
                    buttons: [
                        {
                            kind: ButtonKind.PRIMARY,
                            action: DialogAction.MODAL_CLOSE,
                            label: $translate('JSP_PCL_FBOT_CLOSE').value
                        }
                    ]
                }
            });
            else throw rawError;
        } finally{
            isPending.value = false;
        }
    }
    function openSuccessModal() {
        const timeoutToRouteChange = setTimeout(()=>{
            closeAllDialogs();
            router.replace({
                name: RouteName.LOGIN
            });
        }, 5000);
        showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                title: $translate('WEB2_RESTORE_PASSWORD_SUCCESS').value,
                confirmMessage: $translate('WEB2_RESTORE_PASSWORD_SUCCESS_DESCRIPTION').value,
                image: getImageOrIcon({
                    alertIcon: AlertIconName.Success
                }).image,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_DO_LOGIN').value
                    }
                ]
            }
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                clearTimeout(timeoutToRouteChange);
                router.replace({
                    name: RouteName.LOGIN
                });
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                clearTimeout(timeoutToRouteChange);
                router.replace({
                    name: RouteName.HOME
                });
            }
        });
    }
    function goToRestorePassword() {
        restorePasswordStore.clearCurrentRestoreData();
        router.replace({
            name: RouteName.RESTORE_PASSWORD
        });
    }
    const image = computed(()=>getImageOrIcon({
            alertIcon: AlertIconName.Fail
        }).image);
    return {
        image,
        fetchInitErrorMessage,
        iconProperties,
        runBeforeUnmount,
        goToRestorePassword,
        formSchema,
        restorePasswordLastStepType,
        uiSchema,
        isPending,
        restorePassword
    };
}
