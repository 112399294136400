import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VForm } from 'web/src/components/Form';
import { useRestorePasswordByPhoneForm } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordByPhoneForm',
    props: {
        isPending: {
            type: Boolean
        }
    },
    emits: [
        "form-submit",
        "form-input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { formSchema, uiSchema, emitSubmit, emitInput } = useRestorePasswordByPhoneForm(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['restore-by-phone__label'])
                }, _toDisplayString(_ctx.$t('WEB2_RESTORE_PASSWORD_BYPHONE_HINT')), 3),
                _createVNode(_unref(VForm), {
                    schema: _unref(formSchema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _ctx.isPending,
                    onSubmit: _unref(emitSubmit),
                    onInput: _unref(emitInput)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onSubmit",
                    "onInput"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'RestorePasswordByPhoneForm'
                ]
            ]);
        };
    }
});
