/* eslint-disable no-param-reassign */ import { computed, onBeforeUnmount, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import GqlApiCaptchaRequiredError from '@leon-hub/api/src/client/graphql/errors/GqlApiCaptchaRequiredError';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { isCaptchaToken } from 'web/src/modules/captcha/store/utils';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
export default function useRestorePasswordMainRoutePage() {
    const { setCaptchaToken, resolveCaptcha } = useCaptchaResolver();
    const restorePasswordStore = useRestorePasswordStore();
    const { handleError } = useErrorHandler(useRouter());
    const isSubmitPending = ref(false);
    const captchaSettings = toRef(restorePasswordStore, 'captchaSettings');
    const isMainFormReady = toRef(restorePasswordStore, 'isMainFormReady');
    const activeTab = toRef(restorePasswordStore, 'activeTab');
    const isRestoreByEmailSent = computed(()=>null !== restorePasswordStore.currentEmail);
    const isRestoreByPhoneSent = computed(()=>null !== restorePasswordStore.currentPhone);
    useBusSafeSubscribe(BusEvent.CAPTCHA_SERVICE_ERROR, (data)=>{
        isSubmitPending.value = false;
        handleError(data.error);
    });
    useBusSafeSubscribe(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, ()=>{
        isSubmitPending.value = false;
    });
    onBeforeUnmount(()=>{
        restorePasswordStore.clearCurrentRestoreData();
    });
    function hasOnlyCaptchaError(formOutput) {
        const { errors } = formOutput;
        return !!errors && !(1 === errors.size && errors.get('captchaAnswer'));
    }
    // TODO: JSON scheme dynamic form validation
    function onInput(payload) {
        const captchaToken = payload.formData[RestorePasswordFieldName.CAPTCHA_TOKEN];
        if (isCaptchaToken(captchaToken)) setCaptchaToken(captchaToken);
    }
    async function sendUidWrapped(emailFormData) {
        if (hasOnlyCaptchaError(emailFormData)) return;
        if (captchaSettings.value.isEnforced) await handleCaptcha(emailFormData);
        await sendUid(emailFormData);
    }
    async function resendRestorePasswordLinkToEmail(param) {
        let { formOutput, onSuccess } = param;
        if (hasOnlyCaptchaError(formOutput)) return;
        try {
            await restorePasswordStore.resendRestorePasswordLinkToEmail(formOutput);
            onSuccess?.();
        } catch (rawError) {
            if (rawError instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(formOutput, rawError.extensions.captchaType);
                await resendRestorePasswordLinkToEmail({
                    formOutput,
                    onSuccess
                });
                return;
            }
            throw rawError;
        }
    }
    async function sendUid(emailFormData) {
        if (hasOnlyCaptchaError(emailFormData)) return;
        try {
            isSubmitPending.value = true;
            await restorePasswordStore.sendRestorePasswordLinkToEmailStore(emailFormData);
        } catch (rawError) {
            if (rawError instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(emailFormData, rawError.extensions.captchaType);
                await sendUid(emailFormData);
                return;
            }
            throw rawError;
        } finally{
            isSubmitPending.value = false;
        }
    }
    async function sendSmsWrapped(phoneFormData) {
        if (hasOnlyCaptchaError(phoneFormData)) return;
        if (captchaSettings.value.isEnforced) await handleCaptcha(phoneFormData);
        await sendSms(phoneFormData);
    }
    async function sendSms(phoneFormData) {
        if (hasOnlyCaptchaError(phoneFormData)) return;
        try {
            isSubmitPending.value = true;
            await restorePasswordStore.requestRestorePasswordSms(phoneFormData);
        } catch (rawError) {
            if (rawError instanceof GqlApiCaptchaRequiredError) {
                await handleCaptcha(phoneFormData, rawError.extensions.captchaType);
                await sendSms(phoneFormData);
                return;
            }
            throw rawError;
        } finally{
            isSubmitPending.value = false;
        }
    }
    async function handleCaptcha(data, forcedCaptchaType) {
        const captchaType = forcedCaptchaType ?? captchaSettings.value.captchaType;
        data.formData[RestorePasswordFieldName.CAPTCHA_TOKEN] = await resolveCaptcha(captchaType);
    }
    return {
        isRestoreByPhoneSent,
        isRestoreByEmailSent,
        isMainFormReady,
        activeTab,
        isSubmitPending,
        resendRestorePasswordLinkToEmail,
        onInput,
        sendSmsWrapped,
        sendUidWrapped
    };
}
