import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { LoginType, validationSchemaRestorePasswordByEmail, validationSchemaRestorePasswordByPhone } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config-names';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useFormsStore } from 'web/src/modules/forms/store';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
let RestorePasswordLastStepPagePrefetch = class RestorePasswordLastStepPagePrefetch extends AbstractPrefetch {
    async prefetch(router, to, from, next) {
        const { email, uid } = to.params;
        this.restorePasswordStore.setWillVerifiedEmail('string' == typeof email ? email : null);
        this.restorePasswordStore.setWillVerifiedUid('string' == typeof uid ? uid : null);
        const restoreType = this.restorePasswordStore.restorePasswordLastStepType;
        if (!restoreType) {
            next({
                name: RouteName.RESTORE_PASSWORD
            });
            return Promise.resolve();
        }
        const currentSchema = restoreType === LoginType.EMAIL ? validationSchemaRestorePasswordByEmail : validationSchemaRestorePasswordByPhone;
        await useFormsStore().fetchSchema(currentSchema);
        if (restoreType === LoginType.EMAIL) await this.restorePasswordStore.verifyRestorePasswordUid();
        next();
        return Promise.resolve();
    }
    constructor(...args){
        super(...args);
        _define_property(this, "restorePasswordStore", useRestorePasswordStore());
    }
};
export { RestorePasswordLastStepPagePrefetch as default };
