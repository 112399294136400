import { computed, toRef } from 'vue';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import { useFormsStore } from 'web/src/modules/forms/store';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { FormControlType, FormFocusType } from 'web/src/components/Form/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
export default function useRestorePasswordByEmailForm(emit) {
    const { $translate } = useI18n();
    const restorePasswordStore = useRestorePasswordStore();
    const formsStore = useFormsStore();
    const restoreBySendLinkToEmail = toRef(formsStore, 'restoreBySendLinkToEmail');
    const captchaSettings = toRef(restorePasswordStore, 'captchaSettings');
    const jsonSchema = computed(()=>restoreBySendLinkToEmail.value ?? {
            properties: {}
        });
    const uiSchema = computed(()=>({
            order: [
                RestorePasswordFieldName.EMAIL,
                RestorePasswordFieldName.CAPTCHA_TOKEN
            ],
            submitButton: {
                id: 'restorePasswordByEmailButton',
                label: $translate('WEB2_RESTORE_PASSWORD_NEXT').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: 'email'
            },
            fields: {
                [RestorePasswordFieldName.EMAIL]: {
                    title: $translate('RESTORE_PASSWORD_INPUT_EMAIL').value,
                    widget: FormControlType.Email,
                    default: '',
                    options: {
                        placeholder: $translate('WEB2_EMAIL_INPUT_PLACEHOLDER').value
                    }
                },
                [RestorePasswordFieldName.CAPTCHA_TOKEN]: {
                    hidden: true,
                    widget: FormControlType.Captcha,
                    options: {
                        captchaRequesterStrategy: CaptchaRequesterStrategy.RESTORE_PASSWORD,
                        captchaEnabled: captchaSettings.value.isEnabledToShow,
                        reCaptchaTheme: captchaSettings.value.theme,
                        isEnforced: captchaSettings.value.isEnforced
                    }
                }
            }
        }));
    function emitSubmit(formData) {
        emit('form-submit', formData);
    }
    function emitInput(payload) {
        emit('form-input', payload);
    }
    return {
        jsonSchema,
        uiSchema,
        emitSubmit,
        emitInput
    };
}
