import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { toRef } from 'vue';
import { LoginType, validationSchemaRestorePasswordSendLinkToEmail, validationSchemaRestorePasswordSendSmsToPhone } from '@leon-hub/api-sdk/src/sdk/sdk';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useFormsStore } from 'web/src/modules/forms/store';
import { useLoginStore } from 'web/src/modules/login/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';
let RestoreMainPagePrefetch = class RestoreMainPagePrefetch extends AbstractPrefetch {
    errorHandler(error) {
        this.restorePasswordStore.setFetchInitErrorMessage(error.message);
    }
    async prefetch(router, to, from, next) {
        const formStore = useFormsStore();
        const loginType = toRef(useLoginStore(), 'loginType');
        const isPhoneTabEnabledOnPasswordRestorePage = toRef(useSiteConfigStore(), 'isPhoneTabEnabledOnPasswordRestorePage');
        // @TODO check: this reload schemas each time we enter restore page
        await Promise.all([
            formStore.fetchSchema(validationSchemaRestorePasswordSendLinkToEmail).catch(this.errorHandler),
            formStore.fetchSchema(validationSchemaRestorePasswordSendSmsToPhone).catch(this.errorHandler)
        ]);
        if (loginType.value !== LoginType.EMAIL && isPhoneTabEnabledOnPasswordRestorePage.value) {
            if (loginType.value === LoginType.PHONE) this.restorePasswordStore.setActiveTab(LoginType.PHONE);
        } else this.restorePasswordStore.setActiveTab(LoginType.EMAIL);
        next();
    }
    constructor(...args){
        super(...args);
        _define_property(this, "restorePasswordStore", useRestorePasswordStore());
    }
};
export { RestoreMainPagePrefetch as default };
