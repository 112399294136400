import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeMount } from 'vue';
import { UntrustedSteps } from 'web/src/modules/restore-password/submodules/enums';
import useUntrustedDeviceRouteComponent from 'web/src/modules/restore-password/submodules/UntrustedDeviceRouteComponent/composables/useUntrustedDeviceRouteComponent';
import { VLoaderDelayed } from 'web/src/components/Loader';
import UntrustedDeviceRecoverForm from 'web/src/modules/restore-password/submodules/UntrustedDeviceRecoverForm/UntrustedDeviceRecoverForm.vue';
import UntrustedDeviceRecoverDeclined from 'web/src/modules/restore-password/submodules/UntrustedDeviceRecoverDeclined/UntrustedDeviceRecoverDeclined.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UntrustedDeviceRouteComponent',
    setup (__props) {
        const { step, loaderProperties, hashId, handleLogin, handleClose, beforeMounted } = useUntrustedDeviceRouteComponent();
        onBeforeMount(()=>{
            beforeMounted();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['udrc'])
            }, [
                _unref(step) === _unref(UntrustedSteps).INITIALIZATION ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), _mergeProps({
                    key: 0,
                    class: _ctx.$style['udrc--loader']
                }, _unref(loaderProperties)), null, 16, [
                    "class"
                ])) : _unref(step) === _unref(UntrustedSteps).RECOVER ? (_openBlock(), _createBlock(UntrustedDeviceRecoverForm, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['udrc--form']),
                    "hash-id": _unref(hashId),
                    onSuccess: _unref(handleLogin)
                }, null, 8, [
                    "class",
                    "hash-id",
                    "onSuccess"
                ])) : _unref(step) === _unref(UntrustedSteps).DECLINED ? (_openBlock(), _createBlock(UntrustedDeviceRecoverDeclined, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['udrc--declined']),
                    onClose: _unref(handleClose)
                }, null, 8, [
                    "class",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'UntrustedDeviceRouteComponent'
                ]
            ]);
        };
    }
});
