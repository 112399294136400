import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
import { onBeforeUnmount } from 'vue';
import { AlertIconName } from '@leon-hub/icons';
import { VLoader } from '@components/loader';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { VForm } from 'web/src/components/Form';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { getImageOrIcon } from 'web/src/modules/icons';
import useRestorePasswordLastRoutePage from 'web/src/modules/restore-password/components/RestorePasswordLastRoutePage/composables/useRestorePasswordLastRoutePage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordLastRoutePage',
    setup (__props) {
        const { fetchInitErrorMessage, iconProperties, runBeforeUnmount, goToRestorePassword, formSchema, restorePasswordLastStepType, uiSchema, isPending, restorePassword } = useRestorePasswordLastRoutePage();
        onBeforeUnmount(runBeforeUnmount);
        return (_ctx, _cache)=>_unref(fetchInitErrorMessage) ? (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                key: 0
            }, _unref(iconProperties), {
                image: _unref(getImageOrIcon)({
                    alertIcon: _unref(AlertIconName).Fail
                }).image,
                class: _ctx.$style['restore-password'],
                heading: _unref(fetchInitErrorMessage)
            }), {
                footer: _withCtx(()=>[
                        _createVNode(_unref(JumbotronFooterButton), {
                            label: _ctx.$t('WEB2_RETRY'),
                            "full-width": "",
                            onClick: _unref(goToRestorePassword)
                        }, null, 8, [
                            "label",
                            "onClick"
                        ])
                    ]),
                _: 1
            }, 16, [
                "image",
                "class",
                "heading"
            ])) : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['restore-password'])
            }, [
                _unref(formSchema) ? _unref(restorePasswordLastStepType) ? (_openBlock(), _createBlock(_unref(VForm), {
                    key: _unref(restorePasswordLastStepType),
                    schema: _unref(formSchema),
                    "ui-schema": _unref(uiSchema),
                    "is-pending": _unref(isPending),
                    onSubmit: _unref(restorePassword)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "is-pending",
                    "onSubmit"
                ])) : _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(VLoader), {
                    key: 0
                })),
                _createVNode(VReCaptchaBadge, {
                    class: _normalizeClass(_ctx.$style['restore-password-page--recaptcha']),
                    lang: _ctx.$lang
                }, null, 8, [
                    "class",
                    "lang"
                ])
            ], 2));
    }
});
