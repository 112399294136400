import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useUntrustedDeviceRecoverDeclined(emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const jumbotronSuccessProperties = computed(()=>({
            heading: $translate('WEB2_DEVICE_RECOVER_OLD_LINK').value,
            icon: IconName.ATTENTION_OUTLINE,
            iconSize: IconSize.SIZE_80,
            iconKind: JumbotronIconKind.ALERT
        }));
    const buttonLinkProperties = computed(()=>({
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true
        }));
    function openHelpModal() {
        router.push({
            name: RouteName.SUPPORT
        });
    }
    function emitClose() {
        emit('close');
        return true;
    }
    return {
        jumbotronSuccessProperties,
        buttonLinkProperties,
        emitClose,
        openHelpModal
    };
}
